import React, { Component } from 'react';
import '../style/main.css';
import '../style/reused_css.css';
import NSNHeader from "../components/header";
import HeroImg from "../components/HeroImg";
import NSNFooter from "../components/footer";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube, faPatreon } from '@fortawesome/free-brands-svg-icons';
import Registration from "../components/Registration";
import YouthChamp from '../static/Expanded_Youth_Season_Banner.jpg';


library.add(faYoutube, faPatreon);

class Home extends Component {

    state = {

    }



    componentDidMount() {

    }

    render() {
        return (
            <div className="mainDiv">
                <NSNHeader title='Ninja Sport Network' link='' linkTitle=''></NSNHeader>
                <div>
                    {/* <div className="infoBanner">
                        NSC Season II Registration is now Live! Scroll Down for Links.
                    </div> */}
                    <div className="flex-container-row">
                        <div className="fullscreenVideo">
                            <div class='embed-container'><iframe className="mainVideo" src={"https://player.vimeo.com/video/736682123?autoplay=1&loop=1&autopause=0&background=1&muted=1"} width="100%" height="auto" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></div>

                            <div className="videoMask">
                                <div className="flex-center-center">
                                    <div className="videoTitle" style={{ marginBottom: "20px" }}>NINJA SPORT NETWORK</div>
                                    <div className="flex-row">
                                        <div style={{ padding: "5px" }}><a className="videoLink" href="https://www.youtube.com/channel/UCeidwTomRFqkERADxH2iYpA" target="_blank"><FontAwesomeIcon icon={['fab', 'youtube']} /> YouTube</a></div>
                                        <div style={{ padding: "5px" }}><a className="videoLink" href="https://www.patreon.com/ninjasportnetwork" target="_blank"><FontAwesomeIcon icon={['fab', 'patreon']} /> Patreon</a></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex-container-row">

                        <div className="homeContent">

                            {/* <div className="appHighlights">
              <div className="sectionDesc" style={{textAlign: "center"}}>
              Our goal is to provide the highest quality coverage of professional ninja sporting events, highlights, and news. Ninja is our passion, and we are dedicated to growing the sport by making it more accessible to viewers everywhere.
                <br/>Competitions are streamed on <a style={{color: "rgb(148,194,228)"}} href="https://www.youtube.com/channel/UCeidwTomRFqkERADxH2iYpA" target="_blank">Youtube</a>
                <br/>If you want to help us in our mission, and also get some cool stuff along the way, check out our <a style={{color: "rgb(148,194,228)"}} href="https://www.patreon.com/ninjasportnetwork" target="_blank">Patreon</a>
              </div>
            </div> */}

                            


                            <div className="gymDiv">
                            
                                <div className="NSCTitle">NINJA SPORT CHAMPIONSHIP</div>
                                <div className="sectionDesc" style={{ marginTop: "30px" }}>NSC is a ninja competition series featuring the best athletes on professional level courses. Our mission is to show more of the top athletes, more often, and on a variety of courses ranging from Speed courses to grippy Burnout courses. </div>
                                {/* <div className="upcoming">Upcoming Competitions</div> */}

                                </div>

                                <div className="blueGradient" style={{marginBottom: "15px"}}>
                                    {/* <div className="flex-container-row"> */}
                                        {/* <div style={{flex: "1", minWidth: "353px"}}> */}
                                            <img src={YouthChamp} width="100%" height="100%"></img>
                                        {/* </div> */}
                                        <div style={{flex: '2', margin: "0px 10px"}}>
                                        <div className="blockTitle">NSC Youth Expanded Season</div>
                                        <div className="blockSubTitle" style={{marginTop: "10px"}}>Details for competitors will be released soon. Interested gyms can apply to host now!</div>
                                        <div className="baseText" style={{marginTop: "10px"}}>NSC is excited to expand our youth season to offer more opportunities for athletes to compete under the NSC format that includes the retry system and combined scoring of three seperate disciplines of ninja. We will be hosting qualifiers around the country to offer more access to athletes and expanding to 3 age divisions (8-9 / 10-11 / 12-13 year olds). All athletes will run Hybrid and Speed courses at each qualifier with the top 10 boys and girls also getting to run a burnout course. More details and the full rule book for the season will be released soon. If you are a gym that is interested in hosting click the button below!</div>
                                        <div style={{width: "100%"}}><a className="nscBtn" href="https://docs.google.com/forms/d/e/1FAIpQLSchgh_kxDWbw3RdxAF6eI06IJI-uVd9sr60v67Z_UuLLUVD6w/viewform?usp=sf_link">Interested Gyms Apply Now to Host</a></div>
                                        </div>
                                    {/* </div> */}
                                </div>

                                <HeroImg title='NSC Competition Registration' page='registration_image'></HeroImg>

                                <Registration></Registration>

                        </div>
                    </div>
                </div>
                <NSNFooter title='' link='' linkTitle=''></NSNFooter>
            </div>
        );
    }
}


export default Home;