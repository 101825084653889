import React, { Component } from 'react';
import '../style/main.css';
import '../style/ath_bio.css';
import { withRouter, Route, Link } from 'react-router-dom';
import { getRunOrder_LeaderBoard } from '../api/api';
import queryString from 'query-string';


function getDefaultState() {
    return {
      comp_id: '',
      run_order: [],
      course_type: '',
      athlete_index: 0,
    athlete_id: 0
    }
  }

class RunOrderBanner extends Component { 

    constructor(props) {
        super(props);
        this.state = getDefaultState();
      }

       
      goTo(id){
          this.props.history.push({
              pathname: '/athlete_bio',
              search: '?athlete_id=' + id + "&comp_id=" + this.state.comp_id + "&course_type=" + this.state.course_type
          })
          window.location.reload(false);
        //   window.open('/athlete_bio?athlete_id=' + id + "&comp_id=" + this.state.comp_id + "&course_type=" + this.state.course_type);
    }

    componentDidMount(){
        console.log(this);
        var queryObj = queryString.parse(this.props.location.search);
        var query_athlete_id = queryObj.athlete_id || '';
        var query_comp_id = queryObj.comp_id || '';
        var query_course_type = queryObj.course_type || 'open_results';
        console.log(query_course_type);
        if(query_comp_id != "" && query_comp_id != null && query_comp_id != undefined && query_course_type != 'overall_results'){
        getRunOrder_LeaderBoard(query_comp_id, query_course_type, 'run_order').then(resp => {
            console.log(resp);

            var athlete_index = resp.results.map(function(x){return x.athlete_id}).indexOf(parseInt(query_athlete_id));
            if(athlete_index < 2){
                athlete_index = 3
            }
            this.setState({ run_order: resp.results, comp_id: query_comp_id, course_type: query_course_type, athlete_index: athlete_index, athlete_id: query_athlete_id});
        });
    }
    }

    render() {
        return (
            <div>
                {this.state.course_type != 'overall_results' && this.state.course_type != '' ? 
                <div>
                <div className="robTitle">Run Order</div>
                <div className="robAthContainer">
                {this.state.run_order.slice(this.state.athlete_index - 2, this.state.athlete_index + 6).map((item, index) => {
                return <div className="robAthBox" onClick={e=>this.goTo(item.athlete_id)}>
                    <div className={this.state.athlete_id == item.athlete_id ? "robAthlete robActiveAthlete" : "robAthlete"}>{item.run_order}. {item.first_name} {item.last_name}</div>
                </div>
                })}
                </div>
                </div>
                : null
                }
            </div>
        );
    }
}


export default (withRouter(RunOrderBanner));
